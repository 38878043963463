import "../styles/pages/page-news.scss";

import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import theme from "theme";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import NewsTile from "components/NewsTile";
import Newsletter from "components/Newsletter";

const ContactDetails = styled.address`
  width: 100%;
  position: absolute;
  font-size: 12px;
  color: ${theme.colors.light};
  top: 236px;

  ${theme.screens.mdScreen} {
    font-size: 11px;
    top: 154px;
  }
  ${theme.screens.smScreen} {
    position: relative;
    top: unset;
    text-align: center;
    padding: 15px;
  }
`;

const DetailsFontColor = styled.span`
  color: ${theme.colors.light};

  ${theme.screens.mdScreen} {
  }
  ${theme.screens.smScreen} {
    color: ${theme.colors.blue};
    font-size: 16px;
  }
`;

const Button = styled.div`
  display: inline-block;
  text-align: center;
  padding: 10px 0;
  margin: 0 15px 3% 0;
  width: 140px;
  border-radius: 4px;
  transition: all 0.25s ease-in-out;

  // Active checks
  color: ${({ active }) => (active ? `${theme.colors.pink}` : "#d5ddf0")};
  border: 1px solid ${({ active }) => (active ? "#cccccc" : "#d5ddf0")};
  cursor: ${({ active }) => (active ? "default" : "pointer")};

  ${theme.mixins.onEvent} {
    color: ${({ active }) => (active ? "inherit" : `${theme.colors.pink}`)};
    box-shadow: ${({ active }) =>
      active ? "unset" : `${theme.shadows.shadow}`};
    filter: ${({ active }) => (active ? "unset" : "contrast(200%)")};
  }

  ${theme.screens.mdScreen} {
    width: 100%;
    margin: unset;
  }
`;

const News = ({ pageContext, data }) => {
  const newsPosts = data.allWpPost.edges;

  return (
    <Layout style={{ position: "relative" }}>
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <SubpageHeader
        title="News"
        image={require("assets/images/news-img-bg.png").default}
      />
      <Newsletter />

      <ContactDetails>
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-sm-auto address__block address__block--no-border">
              <DetailsFontColor>
                <strong>Media Contacts</strong>
                <br />
                Roddy Langley
                <br />
                Lysander PR Limited
                <br />
                roddy@lysanderpr.com
                <br />
                +44 7547 901 618
                <br />
              </DetailsFontColor>
            </div>
            <div className="col-lg-2 col-sm-auto address__block address__block--no-border">
              <br />
              <DetailsFontColor>
                Helen Wright
                <br />
                Lysander PR Limited
                <br />
                helen@lysanderpr.com
                <br />
                +44 7842 729 579
              </DetailsFontColor>
            </div>
            <div className="col-lg-2 col-sm-auto address__block address__block--no-border">
              <br />
              <DetailsFontColor>
                Steve Colton
                <br />
                Lysander PR Limited
                <br />
                steve@lysanderpr.com
                <br />
                +44 7554 889 056
              </DetailsFontColor>
            </div>
          </div>
        </div>
      </ContactDetails>
      <section className="section-news">
        <div className="container">
          <div className="section-news__buttons">
            <Link to="/news/" className="section-news__button">
              <Button active="true">News</Button>
            </Link>
            <Link to="/blog/" className="section-news__button">
              <Button>Blog</Button>
            </Link>
          </div>
          <div className="row section-news__row">
            {newsPosts?.map((edges, index) => {
              const item = edges.node;
              return (
                <div
                  className="col-lg-4 col-md-6 section-news__tile"
                  key={index}
                >
                  <NewsTile
                    link={item.uri}
                    image={item.featuredImage?.node.sourceUrl}
                    date={item.date}
                    title={item.title}
                    desc={item.newsPostShortDescription.shortDescription}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query newsPagePosts {
    allWpPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          date(formatString: "D MMMM YYYY")
          featuredImage {
            node {
              sourceUrl
            }
          }
          slug
          seo {
            metaDesc
            title
          }
          title
          uri
          newsPostShortDescription {
            shortDescription
          }
          language {
            name
            locale
            code
            slug
          }
        }
      }
    }
  }
`;

export default News;
